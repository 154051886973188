.ql-reader,
.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #ffffffaa;
}

.ql-snow.ql-toolbar {
  display: block;
  background: var(--bgMainCommon);
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.ql-reader,
.ql-editor {
  min-height: 3em;
}

.ql-rtb-disabled .ql-toolbar {
  display: none !important;
}

.ql-rtb-disabled .ql-container {
  border: none !important;
}

.ql-rtb-disabled {
  background-color: #d6d7d9;
  opacity: 0.5 !important;
  filter: grayscale(1);
  cursor: not-allowed !important;
  border: 1px dashed #aaa !important;
  border-radius: 0.5em;
  cursor: not-allowed;
}

.ql-editor > p {
  margin: unset !important;
  display: block;
  margin-block-start: 0.5em !important;
  margin-block-end: 0.5em !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ql-reader,
.ql-container {
  flex: 1;
}

.ql-normal .ql-reader,
.ql-normal .ql-editor {
  max-height: 333px;
}

.ql-taller .ql-reader,
.ql-taller .ql-editor {
  max-height: calc(100vh - 64px - 2rem - 124px);
}

.ql-min-h128 .ql-reader,
.ql-min-h128 .ql-editor {
  min-height: 128px;
}

.ql-xtaller .ql-reader,
.ql-xtaller .ql-editor {
  max-height: 200vh;
}

.ql-editor ol,
.ql-editor ul {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.ql-toolbar,
.ql-container {
  border: none !important;
}

.ql-toolbar {
  border-bottom: var(--outlined) !important;
}
.ql-not-editing .ql-toolbar {
  display: none !important;
}

.ql-not-editing .ql-container {
  border-radius: 0.5em !important;
}

.quill {
  border-radius: 0.5em !important;
  border: var(--outlined) !important;
}

.quill:hover {
  border: 1px solid var(--purpleCommon) !important;
}

div.custom-html li.ql-indent-1:not(.ql-direction-rtl) {
  margin-left: 4.5em;
}
